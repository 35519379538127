import API from '@/api';

async function generateCampaignReport(campaignId) {
  const response = await API.get(`/campaigns/${campaignId}/generate-report`, {
    responseType: 'blob',
  });

  return response.data;
}

async function getCampaigns(page, sortBy, filter) {
  let endpoint = `/campaigns?page=${page}`;

  if (sortBy) {
    endpoint += `&sort=${sortBy}`;
  }

  if (filter) {
    endpoint += `&filter[name]=${filter}`;
  }

  const response = await API.get(endpoint);
  return response.data;
}

async function getCampaign(campaignId) {
  let endpoint = `/campaigns/${campaignId}`;
  const { data } = await API.get(endpoint);

  return data;
}

async function createCampaign(payload) {
  const response = await API.post('/campaigns/', payload);
  return response.data;
}

async function update(campaignId, campaignFormData) {
  const { data } = await API.put(`/campaigns/${campaignId}`, campaignFormData);

  return data;
}

async function deleteCampaign(campaignId) {
  const { data } = await API.delete(`/campaigns/${campaignId}`);
  return data;
}

async function getCampaignAdvertisers() {
  const response = await API.get('/campaign-advertisers');
  return response.data;
}

//Orders:
async function getOrders(campaignId, params) {
  const { data } = await API.get(`/campaigns/${campaignId}/orders`, params);

  return data;
}

async function createOrder(payload) {
  const response = await API.post(`campaigns/${payload.campaignId}/orders/`, payload);
  return response.data;
}

async function getPotentialTargets() {
  const { data } = await API.get(`/targets`);
  return data;
}

async function getLastOrderProcessingsDate() {
  const { data } = await API.get(`/last-order-processings-date`);
  return data;
}

export default {
  generateCampaignReport,
  getCampaigns,
  getCampaign,
  createCampaign,
  update,
  deleteCampaign,
  getCampaignAdvertisers,
  getOrders,
  createOrder,
  getPotentialTargets,
  getLastOrderProcessingsDate,
};
